import React from 'react';
import './Popup1.css';
import sriRamanujaImage from './assets/sri-ramanuja-image.jpg';
import templeImage from './assets/temple-image.jpg';

const Popup1 = ({ content, closePopup }) => {
  return (
    <div className="popup1-overlay" onClick={closePopup}>
      <div className="popup1-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={closePopup}>X</button>
        <div className="app">
      <div className="row">
        <img src= {sriRamanujaImage} alt="Description 1" className="image" />
        <div className="description right">
          <h1>History</h1>
          <p> One of the unique features here is that, mUlavar emperumAn is also known by the name of the dhivya dhEsam, i.e., vAnamAmalai and thOthAdhrinAthan. He is surrounded by rishis, thiloththamai and Urvasi who descended to serve emperumAn in this dhivya dhEsam. vAnamAmalai (thOthAdhri) also known as nAngunEri is one of the svayam vyaktha kshEthrams. emperumAn appears in archAvathAram through many ways – dhivya mangaLa vigraham (divine auspicious form) consecrated by rishis, kings, dhEvas, etc., and also as svayam vyaktham (appearing on his own). SrIrangam, thiruvEngadam (thirumalai), SrImushNam, thOthAdhri (thOtha parvam), pushkaram, sAlagrAmam, badhrikAsramam and naimisAraNyam are glorified as svayam vyaktha kshEthrams where emperumAn appears himself on his own will. Of these, thOthAdhri is of great importance, since nammAzhwAr performed mangaLAsAsanam to the emperumAn here and also performed his first saraNAgathi (surrender) for this dhivya dhEsam emperumAn.</p>
        </div>
      </div>
      <div className="row">
        <div className="description left">
          <h1>Vanamamalai Mutt</h1>
          <p>
          The Vanamamalai Perumal Temple, also known as Arulmigu Sree Vanamamalai Totatri Perumal Temple is a Hindu temple dedicated to the god Vishnu, in Vanamamalai or Totadrikshetram (Nanguneri), Tamil Nadu, India on the banks of Thamiraparani river. Constructed in the Dravidian style of architecture, the temple is glorified in the Nalayira Divya Prabandham, the early medieval Tamil canon of the Alvar saints from the 6th–9th centuries CE. It is one of the 108 Divya Desams shrines dedicated to Vishnu. Vishnu is worshipped here as Vanamalai Perumal and his consort Lakshmi as Varamanagi.[1] The temple is believed to have been built by the Pandyas, with later contributions from Vijayanagara kings and Madurai Nayaks. The temple covers an area of 5 acres (20,000 m2) and has a five-tiered temple tower. 
          <p>The temple is one of the eight Svayambu Kshetrams, the eight temples associated with Vishnu, that manifested on its own. Vanamamalai is believed to have appeared to Adishesha and Garuda. Six daily rituals and a dozen yearly festivals are held at the temple, of which the chariot festival, celebrated during the Tamil month of Chittirai (March–April), is the most prominent. The temple is maintained and administered by the Vanamamalai Mutt.
          </p>
          </p>
        </div>
        <img src= {templeImage} alt="Description 1" className="image" />
      </div>
    </div>
      </div>
    </div>
  );
};

export default Popup1;
