import React, { useState , useRef } from 'react';
import './App.css';
import bannerImage from './assets/banner-image.png'; 
import sriRamanujaImage from './assets/sri-ramanuja-image.jpg';
import news1Image from './assets/news-image1.jpg';
import news2Image from './assets/news-image2.jpg';
import news3Image from './assets/news-image3.jpg';
import news4Image from './assets/news-image4.jpg';
import videoImage from './assets/video-image1.jpg';
import newsImage from './assets/news-image.jpg';
import announcement1Image from './assets/announcement-image.jpg';
import announcement2Image from './assets/announcement-image.jpg';
import announcement3Image from './assets/announcement-image.jpg';
import announcement4Image from './assets/announcement-image.jpg';
import mapImage from './assets/map-image.jpg';
import templeImage from './assets/temple-image.jpg';
import EventImage from './assets/event-item.jpg';
import logoImage from './assets/logo-image.png';
import Popup from './Popup';
import Popup1 from './Popup1';
import Popup2 from './Popup2';
import Popup3 from './Popup3';
import Popup4 from './Popup4';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupType, setPopupType] = useState(null);
  const announcementsRef = useRef(null);

  const openPopup = (content, type) => {
    setPopupContent(content);
    setPopupType(type);
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupType(null);
  };
  const scrollAnnouncements = (direction) => {
    if (announcementsRef.current) {
      announcementsRef.current.scrollBy({
        left: direction === 'left' ? -300 : 300,
        behavior: 'smooth',
      });
    }
  };

  const loremText1 = " Our Sri Vanamamalai Mutt is one of the oldest mutts in Srivaishnava Sampradayam with rich culture and heritage."
  const loremText2 = " Summer camp Registration Details Enroll Now here"
  const loremText3 = " HH Madurakavi Vanamamalai Jeer Swamy blessed His VanaMahachala Vidyapeetam vidyarthi's"
  const loremText4 = " Important Appeal/Announcement from the mutt"
  return (
    <div className="container">
      {isPopupOpen && popupType === 'popup' && <Popup content={popupContent} closePopup={closePopup} />}
      {isPopupOpen && popupType === 'popup1' && <Popup1 content={popupContent} closePopup={closePopup} />}
      {isPopupOpen && popupType === 'popup2' && <Popup2 content={popupContent} closePopup={closePopup} />}
      {isPopupOpen && popupType === 'popup3' && <Popup3 content={popupContent} closePopup={closePopup} />}
      {isPopupOpen && popupType === 'popup4' && <Popup4 content={popupContent} closePopup={closePopup} />}
      <div className="logo-container">
      <header>
      <div className="Header-text">VANAMAMALAI MUTT</div>
      <img src={logoImage} alt="Vanamamalai Mutt Logo" className="logo" /> {/* Add the logo image */}
      </header>
      </div>
  
      <header>
      <div className="vanamamalai-container">
  
        <nav classname="navbar">
          <ul>
            <li><a href="#home">Home </a></li>
            <li><a href="#patashala">Patashala</a></li>
            <li><a href="#jeeyar">Jeeyar</a></li>
            <li><a href="#temple">Temple</a></li>
            <li><a href="#gallery">Gallery</a></li>
          </ul>
        </nav>
        </div>
      </header>
  
      <main>
        <section className="top-section">
          <div className="highlight-section">
            <img src={sriRamanujaImage} alt="Sri Ramanuja Avathara Urchavam 2024" />
            <div className="highlight-text">
            <a href="#" className="highlight-link" onClick={() => openPopup("Details about Sri Ramanuja Avathara Urchavam 2024", 'popup')}>
                <h2>thOthAdhri nAthan with srI dhEvi, bhU dhEvi</h2>
              </a>  
              <p>One of the unique features here is that, mUlavar emperumAn is also known by the name of the dhivya dhEsam, i.e., vAnamAmalai and thOthAdhrinAthan. He is surrounded by rishis, thiloththamai and Urvasi who descended to serve emperumAn in this dhivya dhEsam.</p>
            </div>
          </div>
          <div className="news-section">
            {Array(1).fill().map((_, index) => (
              <>
                <div className="news1-item" key={index}>
                  <img src={news1Image} alt="News1" />
                  <a href="#" className="highlight-link" onClick={() => openPopup(loremText1, 'popup1')}>{loremText1}</a>
                </div>
                <div className="news2-item" key={index + 1}>
                  <img src={news2Image} alt="News2" />
                  <a href="#" className="highlight-link" onClick={() => openPopup(loremText2, 'popup2')}>{loremText2}</a>
                </div>
                <div className="news3-item" key={index + 2}>
                  <img src={news3Image} alt="News3" />
                  <a href="#" className="highlight-link" onClick={() => openPopup(loremText3, 'popup3')}>{loremText3}</a>
                </div>
                <div className="news4-item" key={index + 3}>
                  <img src={news4Image} alt="News4" />
                  <a href="#" className="highlight-link" onClick={() => openPopup(loremText4, 'popup4')}>{loremText4}</a>
                </div>
              </>
            ))}
          </div>
          <section className='right-column'>
            <section className="youtube">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3RtOH-g5bIM" title="YouTube video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </section>
            <section>
            <div className="announcements">
              <h3>Announcements</h3>
              <div className="announcement-scroller">
    
                {Array(1).fill().map((_, index) => (
              <>
                <div className="announcement1-item" key={index}>
                  <img src={announcement1Image} alt="announcement1" />
                  <a href="#" className="announcement-link" onClick={() => openPopup(loremText1, 'popup1')}>{loremText1}</a>
                </div>
                <div className="announcement2-item" key={index + 1}>
                  <img src={announcement2Image} alt="announcement2" />
                  <a href="#" className="announcement-link" onClick={() => openPopup(loremText2, 'popup2')}>{loremText2}</a>
                </div>
                <div className="announcement3-item" key={index + 2}>
                  <img src={announcement3Image} alt="announcement3" />
                  <a href="#" className="announcement-link" onClick={() => openPopup(loremText3, 'popup3')}>{loremText3}</a>
                </div>
                <div className="announcement4-item" key={index + 3}>
                  <img src={announcement4Image} alt="announcement4" />
                  <a href="#" className="announcement-link" onClick={() => openPopup(loremText4, 'popup4')}>{loremText4}</a>
                </div>
              </>
            ))}
                </div>
              </div>
           
            </section>
        
          </section>
        </section>
        <section className="bottom-section">
          <div className="jeeyars-travel">
            <h2>Jeeyars Travel</h2>
            <div className="itinerary-columns">
              {['Upcoming Itinerary', '2023', '2022'].map((year, index) => (
                <div className="year" key={index}>
                  <h3>{year}</h3>
                  <img src={mapImage} alt={`${year} itinerary`} />
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
              ))}
            </div>
            <div className="videos">
              {Array(4).fill().map((_, index) => (
                <div className="video-item" key={index}>
                  <img src={videoImage} alt="Video Thumbnail" />
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                </div>
              ))}
            </div>
          </div>
          <aside className="event-calendar">
            <h2>Event Calendar</h2>
            <div className="scrollable">
              {Array(5).fill().map((_, index) => (
                <div className="event-item" key={index}>
                  <img src={EventImage} alt="Event" />
                  <p>22/05/2024 Alwar Thirunagari, Swami Namalwar Visakha Usavam: Ninth Thiruvaai Thirunavukarasu</p>
                </div>
              ))}
            </div>
          </aside>
        </section>
        <section className="vanamamalai-temple-section">
          <h1>Vanamamalai Temple</h1>
          <div className="banner">
            <img src={bannerImage} alt="Vanamamalai Temple Banner" />
          </div>
          <div className="vanamamalai-temple-content">
            {Array(6).fill().map((_, index) => (
              <div className="temple-item" key={index}>
                <img src={templeImage} alt="Vanamamalai Temple" />
                <p>Lorem ipsum dolor sit amet</p>
              </div>
            ))}
          </div>
          <div className="event-info">
            <h2>Sri Vanamamalai Dhivya Desam</h2>
            <p>On 24/05/2024 Vaikasi Friday - Sri Varamangai Nachiyar samedha Sri Deivanayaga Perumal Purappadu at Sri Vanamamalai Dhivya Desam.</p>
          </div>
        </section>
        <section className="patashala-section">
          <header className="header">
            <h1>Patashala</h1>
            <nav>
              <ul>
                <li>Who we are</li>
                <li>Admissions</li>
                <li>Contact us</li>
              </ul>
            </nav>
          </header>
          <div className="about-enquiry-container">
            <section className="about">
              <h2>About</h2>
              <img src={newsImage} alt="About Image" />
              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </section>
            <section className="enquiry">
              <h2>Enquiry</h2>
              <form>
                <input type="text" placeholder="Name" />
                <input type="text" placeholder="Phone Number" />
                <input type="text" placeholder="Subject" />
                <textarea placeholder="Query"></textarea>
                <button type="submit">Submit</button>
              </form>
            </section>
          </div>
          <div className="content">
            <section className="course">
              <h2>Course</h2>
              <img src={newsImage} alt="Course Image" />
              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </section>
            <section className="admission">
              <h2>Admission</h2>
              <img src={newsImage} alt="Admission Image" />
              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </section>
            <section className="course">
              <h2>Course</h2>
              <img src={newsImage} alt="Course Image" />
              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </section>
          </div>
          <div className="gallery">
            <section className="gallery-section">
              <div className="gallery-container">
                <div className="gallery-column">
                  {Array(2).fill().map((_, index) => (
                    <div className="gallery-item" key={index}>
                      <img src={newsImage} alt="Gallery Item" />
                      <p>Lorem ipsum dolor sit amet amet</p>
                      <a href="#">Learn more</a>
                    </div>
                  ))}
                </div>
                <div className="gallery-column">
                  {Array(2).fill().map((_, index) => (
                    <div className="gallery-item" key={index}>
                      <img src={newsImage} alt="Gallery Item" />
                      <p>Lorem ipsum dolor sit amet amet</p>
                      <a href="#">Learn more</a>
                    </div>
                  ))}
                </div>
                <div className="gallery-column">
                  {Array(2).fill().map((_, index) => (
                    <div className="gallery-item" key={index}>
                      <img src={newsImage} alt="Gallery Item" />
                      <p>Lorem ipsum dolor sit amet amet</p>
                      <a href="#">Learn more</a>
                    </div>
                  ))}
                </div>
                <div className="gallery-column map-column">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63817.68920249036!2d-74.00810084554821!3d40.70583105464732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2590576b1cc3b%3A0x9e02b6e8e22f626f!2sStatue%20of%20Liberty%2C%20New%20York%2C%20NY!5e0!3m2!1sen!2sus!4v1624357734415!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section className="gallery-section">
          <h2 className="gallery-heading">Gallery</h2>
          <div className="gallery-container">
            {Array(10).fill().map((_, index) => (
              <div className="gallery-item" key={index}>
                <img src={newsImage} alt="Gallery Item" />
                <p>Lorem ipsum dolor sit amet amet</p>
                <a href="#">Learn more </a>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>

  );
}

export default App;
